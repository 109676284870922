import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/Layout';

// import aboutUsPicture from '../assets/images/about.jpg';

const Generic = () => (
  <Layout>
    <Helmet>
      <title>About Us</title>
      <meta name="description" content="Generic Page" />
    </Helmet>

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>About Us</h1>
          </header>
          {/* <span className="image main"><img src={aboutUsPicture} alt="" /></span> */}
          <p>Ryecliff, LLC is the only one stop shop for all of your IT and security needs. We offer a wide variety of services and solutions for all industries and focus on building long term relationships with our clients. Most businesses are not at maximum profitability because they are not up date with their technology.</p>
          <p>With Ryecliff, LLC in your corner, we can help you make more with very efficient, profit maximizing solutions. Our number concern is always the well being of our clients. Ethics play a strong role within our company and we only employ and contract with those who meet our standards. We do not believe in fixing things that are not broken, and always stick to the golden rule; “honesty is the best policy.”</p>
        </div>
      </section>
    </div>

  </Layout>
);

export default Generic;
